// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-nunito"
import "typeface-alegreya"

/**
 * One could put some only-in-browser logic here...
 */
export const onClientEntry = () => {}
