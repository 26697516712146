exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-engineers-a-tableau-web-data-connector-generator-js": () => import("./../../../src/pages/engineers/a-tableau-web-data-connector-generator.js" /* webpackChunkName: "component---src-pages-engineers-a-tableau-web-data-connector-generator-js" */),
  "component---src-pages-engineers-better-wdc-performance-with-promises-js": () => import("./../../../src/pages/engineers/better-wdc-performance-with-promises.js" /* webpackChunkName: "component---src-pages-engineers-better-wdc-performance-with-promises-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-sv-js": () => import("./../../../src/pages/index.sv.js" /* webpackChunkName: "component---src-pages-index-sv-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-posts-sv-js": () => import("./../../../src/pages/posts.sv.js" /* webpackChunkName: "component---src-pages-posts-sv-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

